import { Icon } from '@wellcometrust/design-system';
import { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import cx from 'classnames';

import { NotificationProps, NotificationVariants } from './shared-notification-configuration';

type SupportBannerProps = NotificationProps & {
  onClose?: () => void;
};

const SupportBanner = (props: SupportBannerProps) => (
  <div
    className={cx(
      'flex items-start flex-wrap py-staticMd px-staticMd sm:px-staticXl lg:px-staticXxl md:items-center text-white',
      NotificationVariants[props.notificationVariant].bannerBackground,
      props.className
    )}
    role="alert"
    data-cy="support-banner"
  >
    <Icon
      name={
        props?.customIcon?.name
          ? props.customIcon.name
          : (NotificationVariants[props.notificationVariant].defaultIcon as IconProps['name'])
      }
      className={cx(
        'flex-none mr-staticXs',
        NotificationVariants[props.notificationVariant].darkTintedText,
        props?.customIcon?.className
      )}
    />
    <div className="flex flex-1 flex-wrap">
      <div
        className={cx('flex-1 text-heading-sm items-center', {
          [props.title?.className]: props.title?.className,
          'mr-staticMd': props.onClose || props.action,
        })}
      >
        {props.title && (
          <strong className={cx('font-bold', NotificationVariants[props.notificationVariant].darkTintedText)}>
            {props.title.text}
          </strong>
        )}
        {props.children && <> {props.children}</>}
      </div>
    </div>
  </div>
);

export default SupportBanner;
