import { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import { ReactNode } from 'react';

export const NotificationVariants = {
  information: {
    bannerBackground: 'bg-blue-05',
    bannerBorder: 'border-blue-50',
    darkBorder: 'border-blue-80',
    darkTintedText: 'text-blue-80',
    defaultIcon: 'iconActionInformation',
    iconHighlight: 'bg-blue-10',
    closeHover: 'hover:bg-blue-10',
    actionActiveBackground: 'active:bg-blue-10',
    actionHoverText: 'hover:text-blue-90',
    actionActiveText: 'active:text-blue-90',
    actionHoverBorder: 'hover:border-blue-90',
  },
  success: {
    bannerBackground: 'bg-green-05',
    bannerBorder: 'border-green-50',
    darkBorder: 'border-green-80',
    darkTintedText: 'text-green-80',
    defaultIcon: 'iconActionTickCircle',
    iconHighlight: 'bg-green-10',
    closeHover: 'hover:bg-green-10',
    actionActiveBackground: 'active:bg-green-10',
    actionHoverText: 'hover:text-green-90',
    actionActiveText: 'active:text-green-90',
    actionHoverBorder: 'hover:border-green-90',
  },
  warning: {
    bannerBackground: 'bg-amber-05',
    bannerBorder: 'border-amber-30',
    darkBorder: 'border-amber-80',
    darkTintedText: 'text-amber-80',
    defaultIcon: 'iconActionExclamation',
    iconHighlight: 'bg-amber-10',
    closeHover: 'hover:bg-amber-10',
    actionActiveBackground: 'active:bg-amber-10',
    actionHoverText: 'hover:text-amber-90',
    actionActiveText: 'active:text-amber-90',
    actionHoverBorder: 'hover:border-amber-90',
  },
  error: {
    bannerBackground: 'bg-red-05',
    bannerBorder: 'border-red-50',
    darkBorder: 'border-red-80',
    darkTintedText: 'text-red-80',
    defaultIcon: 'iconActionExclamationTriangle',
    iconHighlight: 'bg-red-10',
    closeHover: 'hover:bg-red-10',
    actionActiveBackground: 'active:bg-red-10',
    actionHoverText: 'hover:text-red-90',
    actionActiveText: 'active:text-red-90',
    actionHoverBorder: 'hover:border-red-90',
  },
  support: {
    bannerBackground: 'bg-cyan-90',
    bannerBorder: 'border-cyan-90',
    darkBorder: 'border-cyan-90',
    darkTintedText: 'text-white',
    defaultIcon: 'iconActionInformation',
    iconHighlight: null,
    closeHover: null,
    actionActiveBackground: null,
    actionHoverText: null,
    actionActiveText: null,
    actionHoverBorder: null,
  },
};

export type NotificationProps = {
  action?: {
    className?: string;
    disabled?: boolean;
    href?: string;
    onClick?: () => void;
    text: string;
  };
  children?: ReactNode;
  className?: string;
  customIcon?: {
    className?: string;
    name?: IconProps['name'];
  };
  contentClassName?: string;
  notificationVariant: keyof typeof NotificationVariants;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  title?: {
    className?: string;
    text: string;
  };
};
