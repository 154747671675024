/**
 * Reusable fetch methods for our application.
 */
import * as Sentry from '@sentry/nextjs';

import CONSTANTS from './constants';
import isApiError from './is-api-error';
import { reportError } from './report-error';

import { FormFieldBody } from 'utils/api/form-field';
import type { ApiResponse } from 'utils/api/types';

const { v1: uuidv1 } = require('uuid');

/**
 * Detect whether the errors returned in the response body from our API
 * contains something we're not expecting.
 *
 * @see {@link https://github.com/wellcometrust/funding-platform-backend/blob/develop/docs/api/version_2.md#failure-4xx-or-5xx-response-codes-format}
 * @see {@link CONSTANTS.API_ERROR_CODES}
 */
const containsUnexpectedError = (errors?: ApiResponse['errors']) => {
  if (!errors) return false;

  const dirtyErrorCodes = [CONSTANTS.API_ERROR_CODES.unhandled, CONSTANTS.API_ERROR_CODES.salesforceError];
  const incomingErrorCodes = errors.map((error) => error.code);

  /**
   * This is a type-safe way of testing whether one array contains values from another.
   * Using .includes() is not type-safe (otherwise that would have been preferable!)
   */
  return dirtyErrorCodes.some((dirtyErrorCode) =>
    incomingErrorCodes.some((incomingErrorCode) => incomingErrorCode === dirtyErrorCode)
  );
};

/**
 * Retrieve the value of a specific browser cookie from Document.cookie.
 *
 * @see {@link https://www.w3schools.blog/get-cookie-by-name-javascript-js}
 */
export const getCookieByName = (name: string): string => {
  const cookies = {};

  document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=');
    cookies[key.trim()] = value;
  });

  return cookies[name];
};

type FetchOptions = RequestInit & {
  isAuthed?: boolean;
  'Content-Type'?: string;
};

/**
 * Our fetch method for all API requests. You can provide a custom 'token' value if you'd like,
 * but it will default to the value of the 'token' cookie.
 *
 * @todo We define the Generic types Data, Errors and Metadata twice - here and in ApiResponse.
 * Ideally we would only need to do this once, because they are the same types.
 */
export const fetchFromApi = async <Data, Errors = {}, Metadata = {}>(uri: string, options?: FetchOptions) => {
  const url = `${process.env.NEXT_PUBLIC_API_DOMAIN}/${uri}`;
  const token = getCookieByName('token');
  const method = options?.method || 'GET';

  const fundingPlatformReqId = uuidv1();

  Sentry.setContext('Middleware Additional Data', {
    mw_request_id: fundingPlatformReqId,
  });

  const response = await fetch(url, {
    ...options,
    method,
    headers: {
      ...options?.headers,
      'X-FUNPLAT-REQUEST-ID': fundingPlatformReqId,
      ...(options?.isAuthed && { Authorization: `Bearer ${token}` }),
      'Content-Type': options?.['Content-Type'] || 'application/json',
    },
  });

  const json: ApiResponse<Data, Errors, Metadata> = await response.json();

  /**
   * If there are unhandled or Salesforce errors then something serious has probably gone
   * wrong - log it to Sentry.
   */
  if (containsUnexpectedError(json.errors)) {
    reportError(new Error(`ApiError: ${method} ${uri}`), {
      errors: json.errors,
      requestBody: options?.body ?? undefined,
    });
  }

  return json;
};

/**
 * Our fetch method for all API requests. You can provide a custom 'token' value if you'd like,
 * but it will default to the value of the 'token' cookie.
 */
export const fetchWithToken = async <Data, Errors = {}, Metadata = {}>(
  uri: string,
  options?: {
    body?: {
      [key: string | number]:
        | string
        | number
        | { [key: string]: string }
        | FormFieldBody[]
        | string[]
        | { [key: string]: string }[]
        | Object;
    };
    method?: string;
    token?: string;
    searchParamString?: string;
  }
) => {
  // If `searchParamString` is provided, include it in the request to add support for filtering, pagination, and ordering.
  const uriWithSearchParams = options?.searchParamString ? `${uri}?${options.searchParamString}` : uri;

  const response = await fetchFromApi<Data, Errors, Metadata>(uriWithSearchParams, {
    isAuthed: true,
    method: options?.method,
    body: options?.body ? JSON.stringify(options?.body) : undefined,
  });

  /**
   * If the user is not authorized, remove the token cookie.
   * This will force the user to log in again.
   * @todo This is a bit of a hack. We should be using refresh tokens.
   * @see {@link https://github.com/wellcometrust/corporate/issues/12683 }
   */

  if (isApiError(response.errors, CONSTANTS.API_ERROR_CODES.notAuthorized)) {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  }

  return response;
};

export const uploadBinaryDataWithToken = async <Data, Errors = {}, Metadata = {}>(
  uri: string,
  options: {
    body: string | ArrayBuffer;
    method?: string;
    token?: string;
    'Content-Type'?: string;
  }
) => {
  const response = await fetchFromApi<Data, Errors, Metadata>(uri, {
    isAuthed: true,
    method: options?.method,
    body: options.body,
    'Content-Type': options?.['Content-Type'],
  });

  return response;
};

export default fetchWithToken;
