import { AnnualReportStatus } from './api/annual-report';
import { ApplicationStatus } from './api/application';
import {
  AcceleratorAwardsStatus,
  AccountProfileStatus,
  OrganisationStatus,
  PositiveActionStatus,
} from './api/application-overview';
import { DetailedAwardedGrantStatus } from './api/awarded-grant';
import { CommitteeAttendanceStatus } from './api/committee';
import { CommitteeLeadReviewStatus } from './api/committee-meeting';
import { CommitteeMembershipStatus } from './api/committee-membership';
import { ExpertReviewStatus } from './api/expert-review';
import { SectionStatus } from './api/section-status';
import { StatusContext } from './status-labels';

/*
 * The same status values sometimes have different labels and style variants depending on what context the status is used in.
 * (For example, the status value `RECEIVED` is `BLUE` in the context of an application but `GREEN` in the context of an expert review.)
 * This file maps all possible status values to style variants for each possible context.
 */

export type StatusVariant = 'AMBER' | 'BLUE' | 'GREEN' | 'GREY' | 'ORANGE';

const annualReportStatusVariants: { [key in AnnualReportStatus]: StatusVariant } = {
  REQUESTED: 'AMBER',
  RECEIVED: 'GREEN',
  EXPIRED: 'GREEN',
};

const applicationStatusVariants: { [key in ApplicationStatus]: StatusVariant } = {
  NOT_AVAILABLE: 'GREY',
  INVITED: 'AMBER',
  DECISION: 'AMBER',
  DECISION_INTERVIEW: 'AMBER',
  DECISION_RECOMMENDATION: 'BLUE',
  RETURNED_TO_APPLICANT: 'AMBER',
  RETURNED_TO_APPLICANT_FROM_WELLCOME: 'AMBER',
  WITH_WELLCOME: 'BLUE',
  WITH_APPLICANT: 'BLUE',
  RECEIVED: 'BLUE',
  WITH_RESEARCH_OFFICE: 'BLUE',
  APPROVED: 'GREEN',
  AWARD_OFFERED: 'GREEN',
  AWARD_ACCEPTED: 'GREEN',
  AWARD_STARTING_SOON: 'GREEN',
  ACTIVE: 'GREEN',
  COMPLETED: 'GREEN',
  CLOSED: 'GREEN',
  SHORTLISTING: 'BLUE',
  EXPERT_REVIEW: 'BLUE',
  Unsubmitted: 'GREY',
  Withdrawn: 'GREY',
  Unsuccessful: 'GREY',
};

const awardedGrantApplicantStatusVariants: { [key in DetailedAwardedGrantStatus]: StatusVariant } = {
  AWARD_OFFERED: 'GREEN',
  AWARD_ACCEPTED: 'GREEN',
  AWARD_STARTING_SOON: 'BLUE',
  ACTIVE: 'BLUE',
  COMPLETED: 'GREY',
  ENDING_SOON: 'BLUE',
  ENDED: 'GREY',
  CLOSED: 'GREY',
  REPORT_DUE: 'GREY',
};

const awardedGrantResearchOfficeStatusVariants: { [key in DetailedAwardedGrantStatus]: StatusVariant } = {
  AWARD_OFFERED: 'GREEN',
  AWARD_ACCEPTED: 'BLUE',
  AWARD_STARTING_SOON: 'BLUE',
  ACTIVE: 'BLUE',
  COMPLETED: 'GREY',
  ENDING_SOON: 'BLUE',
  ENDED: 'GREY',
  CLOSED: 'GREY',
  REPORT_DUE: 'GREY',
};

const expertReviewStatusVariants: { [key in ExpertReviewStatus]: StatusVariant } = {
  REQUESTED: 'AMBER',
  IN_REVIEW: 'AMBER',
  IN_PROGRESS: 'BLUE',
  RECEIVED: 'GREEN',
  COMPLETE: 'GREEN',
  NOT_PROGRESSED: 'GREY',
};

const membershipStatusVariants: { [key in CommitteeMembershipStatus]: string } = {
  ACTIVE_MEMBER: 'GREEN',
  PAST_MEMBER: 'GREY',
};

const meetingStatusVariants: { [key in CommitteeAttendanceStatus]: string } = {
  ATTENDED: 'GREEN',
  NOT_ATTENDED: 'GREY',
  CONTRIBUTED: 'GREY',
};

const pastMeetingStatusVariants: { [key in CommitteeAttendanceStatus]: string } = {
  ATTENDED: 'GREY',
  NOT_ATTENDED: 'GREY',
  CONTRIBUTED: 'GREY',
};

const committeeLeadReviewStatusVariants: { [key in CommitteeLeadReviewStatus]: StatusVariant } = {
  NOT_STARTED: 'GREY',
  IN_PROGRESS: 'BLUE',
  COMPLETE: 'GREEN',
};

const sectionStatusVariants: {
  [key in SectionStatus['applicantStatus'] | SectionStatus['researchOfficeStatus']]: StatusVariant;
} = {
  Complete: 'GREEN',
  Checked: 'GREEN',
  NeedsReview: 'ORANGE',
  InProgress: 'BLUE',
  NotStarted: 'GREY',
  NotChecked: 'GREY',
};

const organisationStatusVariants: { [key in OrganisationStatus]: StatusVariant } = {
  NOT_STARTED: 'GREY',
  VERIFIED: 'GREEN',
  UNVERIFIED: 'BLUE',
  REJECTED: 'ORANGE',
};

const accountProfileStatusVariants: { [key in AccountProfileStatus]: StatusVariant } = {
  NOT_COMPLETE: 'BLUE',
  COMPLETE: 'GREEN',
};

const positiveActionStatusVariants: { [key in PositiveActionStatus]: StatusVariant } = {
  NotStarted: 'GREY',
  InProgress: 'BLUE',
  Complete: 'GREEN',
};

const acceleratorAwardsStatusVariants: { [key in AcceleratorAwardsStatus]: StatusVariant } = {
  NotStarted: 'GREY',
  InProgress: 'BLUE',
  Complete: 'GREEN',
};

const statusVariantByContext = {
  annualReport: annualReportStatusVariants,
  applicationApplicant: applicationStatusVariants,
  applicationResearchOffice: applicationStatusVariants,
  organisation: organisationStatusVariants,
  accountProfile: accountProfileStatusVariants,
  positiveAction: positiveActionStatusVariants,
  acceleratorAwards: acceleratorAwardsStatusVariants,
  section: sectionStatusVariants,
  expertReview: expertReviewStatusVariants,
  membership: membershipStatusVariants,
  meeting: meetingStatusVariants,
  pastMeeting: pastMeetingStatusVariants,
  recentMeeting: meetingStatusVariants, // added to make the status colour for recent meetings green based on Dana's design
  committeeLeadReview: committeeLeadReviewStatusVariants,
  awardedGrantApplicant: awardedGrantApplicantStatusVariants,
  awardedGrantResearchOffice: awardedGrantResearchOfficeStatusVariants,
};

export const getStatusVariant = (status: string, context: StatusContext): StatusVariant => {
  const statusVariantMap = statusVariantByContext[context];
  return statusVariantMap[status];
};
