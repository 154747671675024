import useSWR from 'swr';

import { ApplicationOverview, getApplicationOverview } from 'utils/api/application-overview';
import CONSTANTS from 'utils/constants';
import isApiError from 'utils/is-api-error';
import { mapSalesforceStatusToApplicationStatus } from 'utils/status';

const cleanTheData = (data?: ApplicationOverview) => {
  try {
    /**
     * If the API returns sections with no pages, we remove them from the list. We're doing this
     * because the API returns the "Coapplicant details" section even if the application has no
     * coapplicants; we don't want to show this to the lead applicant if this is the case, because
     * the application may not require any coapplicants anyway.
     */
    const sections = data.form.pageGroups.filter((section) => section.pages.length > 0);

    return {
      ...data,
      form: {
        ...data.form,
        pageGroups: sections,
      },
    };
  } catch {
    return data;
  }
};

const getApplicationStatus = (data: ApplicationOverview | null) => {
  if (!data) return;
  return mapSalesforceStatusToApplicationStatus(data.displayStatus, data.submissionStatus, data.notProgressedStatus);
};

export const useApplicationOverview = (id: string) => {
  const response = useSWR(id ? `api/application/overview/${id}` : null, () => getApplicationOverview(id));

  return {
    ...response,
    data: cleanTheData(response?.data?.data),
    staticFormId: cleanTheData(response?.data?.data)?.staticFormId,
    applicationStatus: getApplicationStatus(response?.data?.data),
    hasOrgBeenRejected:
      response?.data?.data.organisation &&
      response?.data?.data.organisation.organisationId &&
      response?.data?.data.organisation.status === 'REJECTED',
    error: response.error || response.data?.errors,
    isLoading: id && !response.data && !response.error,
    notFound: isApiError(response.data?.errors, CONSTANTS.API_ERROR_CODES.notFound),
    notAvailable: response.data?.data?.dataAvailability === 'NOT_AVAILABLE',
    notAuthorized: isApiError(response.data?.errors, CONSTANTS.API_ERROR_CODES.notAuthorized),
  };
};

export default useApplicationOverview;
